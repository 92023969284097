<template>
  <div class="cart-mobile page-mobile">
    <div class="no-product" v-if="cart.length === 0">
      <div>
        <img src="img/svg/custom-cartempty.svg" alt="cart" />
        No tiene productos en su Carrito
      </div>
    </div>

    <v-container v-if="cart.length > 0">
      <v-row v-for="(item, i) in cart" :key="i">
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <div class="content-grid">
                <div class="img">
                  <img :src="item.photo | urlImage" :alt="item.name" />
                </div>
                <div class="content">
                  <div class="close" @click="deleteProduct(item)">
                    <v-icon>mdi-close</v-icon>
                  </div>
                  <div class="title">{{ item.name }}</div>
                  <div class="grid-info">
                    <div class="price">$ {{ item.price }}</div>
                    <div class="count">
                      <div class="btn-minus">
                        <span @click="btnCount(item, i, 'remove')">
                          <v-icon>mdi-minus</v-icon>
                        </span>
                      </div>
                      <div class="number">
                        <input type="number" v-model="item.count" />
                      </div>
                      <div class="btn-plus">
                        <span @click="btnCount(item, i, 'add')">
                          <v-icon>mdi-plus</v-icon>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-footer
      v-if="cart.length > 0"
      absolute
      bottom
      color="white"
      elevation="3"
      class="footer"
    >
      <v-container>
        <v-row>
          <v-col cols="12" style="padding: 12px 0px">
            <div class="grid-content">
              <div class="col1">Total:</div>
              <div class="col2">
                <table>
                  <tr>
                    <td>
                      <div>{{ totalDolar() }} $</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>{{ totalBsFormat() }} Bs</div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row style="margin-top: 0">
          <v-btn block color="second" class="white--text" @click="btnNext()">
            Siguiente
          </v-btn>
        </v-row>
      </v-container>
    </v-footer>

    <AddOrderMobile :dialog.sync="dialog" :product.sync="addProduct" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AddOrderMobile from "./add-order.vue";

export default {
  name: "Cart",
  data: () => ({
    total: 0,
    dialog: false,
    addProduct: {},
    valueDolar: 0,
    valueBs: 0,
  }),
  components: {
    AddOrderMobile,
  },
  computed: {
    ...mapState(["cart", "products"]),
  },
  created() {
    this.calcTotal();
  },
  methods: {
    ...mapActions(["setCart", "setProduct"]),
    calcTotal() {
      this.total = 0;
      this.cart.map((item) => {
        const unidad = item.count * item.price;
        this.total = this.total + unidad;
      });
    },
    btnCount(item, index, type) {
      item.count = item.count ? item.count : 0;
      switch (type) {
        case "add":
          if (item.count < item.stock) {
            item.count = item.count + 1;
          }
          break;
        case "remove":
          item.count = item.count - 1;
          item.count = item.count <= 0 ? 0 : item.count;
          break;
      }
      const cart = this.cart;
      cart[index] = item;
      this.setCart({ cart: [...cart] });
      this.calcTotal();
    },
    deleteProduct(list) {
      const newCart = [];
      const cart = this.cart;
      const products = this.products;
      const index = cart.findIndex((item) => item.id.indexOf(list.id) > -1);
      delete cart[index];
      cart.map((item) => {
        if (item) {
          newCart.push(item);
        }
      });
      this.setCart({ cart: newCart });
      this.calcTotal();
      products[list.mark.name].map((item) => {
        if (item.id === list.id) {
          item.count = 0;
        }
      });
      this.setProduct({
        products: products[list.mark.name],
        name: list.mark.name,
      });
    },
    totalDolar() {
      const format = new Intl.NumberFormat("en-EN", {
        minimumFractionDigits: 2,
      }).format(this.total);
      this.valueDolar = format;
      return format;
    },
    totalBsFormat() {
      const moneda = this.formatMoneda();
      let result = this.total * moneda;
      result = result.toFixed(2);
      const format = new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(result);
      this.valueBs = format;
      return format;
    },
    formatMoneda() {
      let moneda = this.$store.state.setting.moneda;
      if (moneda) {
        const arrayMoneda = moneda.split(".");
        if (arrayMoneda.length > 2) {
          moneda = moneda.split(".").join("");
          moneda = moneda.replace(",", ".");
          moneda = parseFloat(moneda);
        } else if (moneda.indexOf(",") !== -1) {
          moneda = moneda.replace(",", ".");
          moneda = parseFloat(moneda);
        } else {
          moneda = parseFloat(moneda);
        }

        return moneda.toFixed(2);
      }
    },
    btnNext() {
      this.dialog = true;
      this.addProduct = {
        cart: this.cart,
        total: this.valueDolar,
        totalBs: this.valueBs,
      };
    },
  },
};
</script>

<style lang="scss">
.cart-mobile {
  overflow-y: auto;
  padding-bottom: 130px;

  .v-card__text {
    padding: 10px 5px;
  }

  .no-product {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 105px);
    font-size: 22px;
    color: #878787;

    div {
      display: grid;
    }

    img {
      margin: auto;
      padding-bottom: 20px;
      width: 5rem;
      opacity: 0.6;
    }
  }

  .content-grid {
    display: grid;
    grid-template-columns: 110px auto;

    .img {
      width: 100%;
      display: flex;
      padding: 6px;

      img {
        border-radius: 5px;
        border: solid 1px #eee;
        object-fit: contain;
        object-position: center;
      }
    }

    .content {
      padding: 10px 8px 10px 12px;
      display: grid;
      align-items: flex-end;

      .close {
        position: absolute;
        top: 6px;
        right: 12px;

        i {
          font-size: 20px;
        }
      }

      .title {
        line-height: 21px;
        display: flex;
        align-items: flex-start;
        height: 100%;
        padding-top: 5px;
      }

      .grid-info {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        .price {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 500;
        }

        .count {
          display: grid;
          grid-template-columns: 30px 60px 30px;

          .btn-minus {
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              border-radius: 100%;
              background: var(--color-primary);
              width: 20px;
              height: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            i {
              color: var(--color-white);
              font-size: 16px;
            }
          }

          .number {
            position: relative;
            display: flex;
            justify-content: center;

            input {
              border: solid 1px var(--color-second);
              width: 50px;
              border-radius: 6px;
              font-size: 16px;
              font-weight: 500;
              text-align: center;
              padding: 2px 8px;
            }
          }

          .btn-plus {
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              border-radius: 100%;
              background: var(--color-primary);
              width: 20px;
              height: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            i {
              color: var(--color-white);
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .footer {
    .grid-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      font-size: 14px;
      font-weight: 500;

      .col2 {
        text-align: right;
        display: flex;
        justify-content: flex-end;

        div {
          background: #eb445a;
          color: #fff;
          padding: 2px 10px;
          border-radius: 4px;
          margin-bottom: 5px;
          display: inline-block;
        }
      }
    }
  }
}

.cart-mobile::-webkit-scrollbar {
  width: 0px;
}
.cart-mobile::-webkit-scrollbar * {
  background: transparent;
}
.cart-mobile::-webkit-scrollbar-thumb {
  background: transparent;
}
</style>
