<template>
  <v-dialog
    v-model="viewModal"
    persistent
    scrollable
    :max-width="maxWidth"
    content-class="dialog crop"
  >
    <v-card>
      <v-card-title class="card-title">
        <span class="headline">Editar Imagen</span>

        <v-spacer></v-spacer>

        <CustomButtonAction
          :btnApply="true"
          :btnCancel="true"
          :loaderApply="loading"
          @onChangeApply="apply()"
          @onChangeCancel="cancel()"
        />
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <div class="center-image">
          <img id="imagePhoto" :src="imgSrc" />
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-row>
          <v-col>
            <v-btn icon @click="imageReset()">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <v-btn icon @click="imageRotate(-90)">
              <v-icon>mdi-undo</v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <v-btn icon @click="imageRotate(90)">
              <v-icon>mdi-redo</v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <v-btn icon @click="imageZoomRemove()">
              <v-icon>mdi-minus-circle-outline</v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <v-btn icon @click="imageZoomAdd()">
              <v-icon>mdi-plus-circle-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import "cropperjs/dist/cropper.css";
import Cropper from "cropperjs";
import CustomButtonAction from "../custom-button-action/CustomButtonAction.vue";

export default {
  name: "CustomCrop",
  data: () => ({
    viewModal: false,
    loading: false,
    cropper: null,
  }),
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
      default: "800",
    },
    imgSrc: {
      type: String,
      default: "",
    },
  },
  watch: {
    dialog: function (val) {
      this.viewModal = val;
      if (this.imgSrc) {
        setTimeout(() => {
          this.initCropper();
        }, 100);
      }
    },
  },
  components: {
    CustomButtonAction,
  },
  methods: {
    initCropper() {
      const image = document.getElementById("imagePhoto");
      this.cropper = new Cropper(image, {
        aspectRatio: 1,
        viewMode: 0,
        zoomable: true,
        dragMode: "move",
        minCropBoxWidth: 150,
        minCropBoxHeight: 150,
      });
    },
    imageReset() {
      if (this.cropper) {
        this.cropper.reset();
      }
    },
    imageRotate(value) {
      if (this.cropper) {
        this.cropper.rotate(value);
      }
    },
    imageZoomRemove() {
      if (this.cropper) {
        this.cropper.zoom(-0.1);
      }
    },
    imageZoomAdd() {
      if (this.cropper) {
        this.cropper.zoom(0.1);
      }
    },
    close() {
      this.viewModal = false;
      this.cropper.destroy();
      this.cropper = null;
      this.$emit("update:dialog", false);
      this.$emit("update:imgSrc", "");
    },
    cancel() {
      this.close();
    },
    apply() {
      const image64 = this.cropper
        .getCroppedCanvas({
          width: 800,
          height: 800,
          imageSmoothingEnabled: true,
          imageSmoothingQuality: "high",
          fillColor: "#fff",
        })
        .toDataURL("image/jpeg", 0.75);
      this.$emit("onChange", image64);
      this.close();
    },
  },
};
</script>

<style lang="scss">
.center-image {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.crop {
  overflow: hidden;
  .v-card > .v-card__text {
    padding: 0px;

    .cropper-bg {
      background-repeat: initial;
    }
  }

  .col {
    display: flex;
    justify-content: center;

    .v-btn i {
      font-size: 2.2rem;
    }
  }

  .theme--light.v-btn.v-btn--icon {
    color: inherit !important;
  }
}
</style>
