<template>
  <v-dialog
    v-model="viewModal"
    fullscreen
    scrollable
    hide-overlay
    transition="dialog-bottom-transition"
    content-class="mobile-dialog"
  >
    <v-card>
      <v-card-title class="card-title">
        <CustomButtonAction :btnBack="true" @onChangeBack="btnBack()" />
        <span class="headline">Detalles del pedido</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container>
            <v-row style="margin-bottom: -50px">
              <v-col cols="12">
                <v-autocomplete
                  v-model="item.client"
                  :items="clients"
                  label="Seleccionar cliente"
                  :item-text="(item) => item.name + ' ' + item.lastName"
                  :item-value="(item) => item"
                  outlined
                  dense
                  color="second"
                  clearable
                  :rules="requiredRules"
                >
                  <template v-slot:item="data">
                    <v-list-item-content color="second">
                      <v-list-item-title>
                        <b>{{ data.item.name }} {{ data.item.lastName }}</b>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <div>{{ data.item.dni | unitMile }}</div>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-radio-group v-model="item.payment" dense>
                  <v-radio
                    color="second"
                    label="Pago total"
                    value="total"
                  ></v-radio>
                  <v-radio
                    color="second"
                    label="Pago por cuotas"
                    value="cuotas"
                  ></v-radio>
                </v-radio-group>

                <v-select
                  v-if="item.payment === 'cuotas'"
                  :items="listCuotas"
                  item-text="name"
                  item-value="id"
                  label="Seleccionar cuotas"
                  outlined
                  color="second"
                  dense
                  v-model="item.week"
                  clearable
                  :rules="requiredRules"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  outlined
                  label="Comentario"
                  v-model="comment"
                  :rules="requiredRules"
                  counter="500"
                  color="second"
                  dense
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <input
                  id="attached"
                  type="file"
                  accept="image/jpeg,image/png,.jpeg,.jpg,.png"
                  style="display: none"
                  @change="processWebImage($event)"
                />
                <div class="subtitle">Adjuntar imagen</div>
                <div class="content-grid">
                  <div class="col-img" v-for="(image, i) in attached" :key="i">
                    <img :src="image | imageDefault" />
                    <div class="remove">
                      <v-icon>mdi-close</v-icon>
                    </div>
                  </div>
                  <label class="col-img" for="attached">
                    <div class="btn-img">
                      <v-icon>mdi-plus</v-icon>
                    </div>
                  </label>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          block
          color="second"
          class="white--text"
          @click="btnSave()"
          :loading="loading"
        >
          Procesar pedido
        </v-btn>
      </v-card-actions>
    </v-card>

    <CustomCrop
      :dialog.sync="modalCrop"
      :imgSrc.sync="photo"
      @onChange="onChangeCrop($event)"
    />
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import useValidate from "../../../../shared/validate/useValidate";
import CustomButtonAction from "../../../../shared/components/custom-button-action/CustomButtonAction.vue";
import CustomCrop from "../../../../shared/components/custom-crop/CustomCrop.vue";
import OrderServices from "../../../../core/services/order-services";
import OrderStorage from "../../../../core/storage/order-storage";

export default {
  name: "AddOrderMobile",
  data: () => ({
    item: { payment: "total" },
    listCuotas: [],
    attached: [],
    viewModal: false,
    modalCrop: false,
    photo: "",
    comment: "",
    loading: false,
    valid: false,
    requiredRules: [useValidate["required"]],
  }),
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
    },
  },
  watch: {
    dialog: function (val) {
      this.viewModal = val;
    },
  },
  computed: {
    ...mapState(["clients", "user"]),
  },
  components: {
    CustomButtonAction,
    CustomCrop,
  },
  created() {
    this.getListCuotas();
  },
  methods: {
    ...mapActions([
      "setCart",
      "clearProducts",
      "getListOrders",
      "getListClients",
    ]),
    btnBack() {
      this.viewModal = false;
      this.listCuotas = [];
      this.attached = [];
      this.photo = "";
      this.comment = "";
      this.$emit("update:dialog", false);
    },
    getListCuotas() {
      this.listCuotas = [
        { id: 1, name: "1 semana" },
        { id: 2, name: "2 semanas" },
        { id: 3, name: "3 semanas" },
        { id: 4, name: "4 semanas" },
        { id: 5, name: "5 semanas" },
      ];
    },
    processWebImage(event) {
      const reader = new FileReader();
      reader.onload = async (readerEvent) => {
        const image64 = readerEvent.target.result;
        this.modalCrop = true;
        this.photo = image64;
      };
      reader.readAsDataURL(event.target.files[0]);
    },
    onChangeCrop(event) {
      this.attached.push(event);
    },
    btnSave() {
      const validate = this.$refs.form.validate();
      if (validate) {
        this.loading = true;
        const { saveOrder } = OrderServices();
        const { orderAdd } = OrderStorage();
        const check = this.item.payment === "total" ? 2 : 1;
        const weeks = [];
        for (let i = 1; i <= this.item.week; i++) {
          let week = 0;
          if (this.item.week === 1) {
            week = `${i} semana`;
          } else {
            week = `${i} semanas`;
          }
          weeks.push({
            week,
            payment: 0,
          });
        }
        const data = {
          ...this.item,
          code: "",
          attached: this.attached,
          seller: this.user,
          products: this.product.cart,
          total: this.product.total,
          totalBs: this.product.totalBs,
          check,
          weeks,
          comment: [
            {
              _id: this.user._id,
              user: `${this.user.name} ${this.user.lastName}`,
              message: this.comment,
              createdAt: new Date(),
            },
          ],
        };

        saveOrder(data).then((resp) => {
          const response = resp.data;
          if (response.code === 201) {
            orderAdd(response.data);
            this.$toast.success("El pedido se ha realizado correctamente");
            this.btnBack();
            this.$emit("update:product", {});
            this.setCart({ cart: [] });
            this.clearProducts({ name: "La-Phée" });
            this.clearProducts({ name: "Skin Up" });
            this.getListOrders();
          }
          this.loading = false;
        });
      }
    },
  },
};
</script>

<style lang="scss">
.mobile-dialog {
  .subtitle {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .row + .row {
    margin-bottom: -30px;
  }

  .content-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;

    .col-img {
      display: block;
      margin: auto;
      width: 100%;
      height: 100px;
      position: relative;

      .remove {
        position: absolute;
        top: -5px;
        right: -5px;
        background: #eb445a;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;

        i {
          font-size: 16px !important;
          color: #fff;
        }
      }

      .btn-img {
        width: 100%;
        height: 100%;
        border: solid 2px #aaa;
        border-style: dashed;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          font-size: 25px !important;
          color: #aaa;
        }
      }
    }
  }
}
</style>
