import axios from "axios";

const OrderServices = () => {
  const getOrderAll = async (check = 1) => {
    return await axios
      .get(`${process.env.VUE_APP_ROOT_API}/order/?check=${check}`)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  const getByIdSeller = async () => {
    return await axios
      .get(`${process.env.VUE_APP_ROOT_API}/order/seller/`)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  const saveOrder = async (data) => {
    return await axios
      .post(`${process.env.VUE_APP_ROOT_API}/order`, data)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  const updateOrder = async (id, data) => {
    return await axios
      .put(`${process.env.VUE_APP_ROOT_API}/order/${id}`, data)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  const updateCheck = async (id, data) => {
    return await axios
      .put(`${process.env.VUE_APP_ROOT_API}/order/check/${id}`, data)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  const deleteOrder = async (id) => {
    return await axios
      .delete(`${process.env.VUE_APP_ROOT_API}/order/${id}`)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  return {
    getOrderAll,
    getByIdSeller,
    saveOrder,
    updateOrder,
    updateCheck,
    deleteOrder,
  };
};

export default OrderServices;
